import ApiClient from './Client';

class SentimentApi {

    registerUser =(postData)=> ApiClient().post('/setToken',postData);

    verifyToken=(token)=>ApiClient().post('/verifyToken',{},{headers:{
        "x-access-token":token
    }}).catch((x)=>{
        console.log(x)
    })

    postComplaint = ( data)=>ApiClient().post('/addComplaint',data).catch((x)=>{
        console.log(x)
    })

    postRequest =(data,token )=>ApiClient().post('/addRequest',data,{headers:{
        "x-access-token":token
    }}).catch((x)=>{
        console.log(x)
    })
    

    postEnquiry =(data,token )=>ApiClient().post('/addEnquiry',data,{headers:{
        "x-access-token":token
    }}).catch((x)=>{
        console.log(x)
    })

    postCES =(data)=>ApiClient().post('/addEffortScore',data).catch((x)=>{
        console.log(x)
    })

    postCompliment=(data)=>ApiClient().post('/addCompliment',data).catch((x)=>{
        console.log(x)
    })

    postSatisfaction =(data)=>ApiClient().post('/addSatisfactionscore',data).catch((x)=>{
        console.log(x)
    })

    postRating =(data)=>ApiClient().post('/addNPS',data).catch((x)=>{
        console.log(x)
    })

}

export default new SentimentApi();