import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.png";
import "../assets/css/complaints.css";
import { FaHome } from 'react-icons/fa';
import SentimentApi from '../api/SentimentApi';
import LoadingBar from 'react-top-loading-bar';
import cabs from "../assets/images/cabs.png";
import { retrieveObjectData, storeObjectData } from './Util';

const Complaint = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [complaint, setComplaint] = useState("");

  const ref = useRef(null);

  const bus = [
    { label: 'CABS', value: 1 },
    { label: 'Old Mutual Life Assurance', value: 2 },
    // { label: 'Retail', value: 3 },
    { label: 'Old Mutual Unit Trusts', value: 4 },
    { label: 'Old Mutual Short-term Insurance', value: 5 },
    { label: 'Old Mutual Finance', value: 6 },
    { label: 'Old Mutual Investments', value: 7 },
    // { label: 'Group Shared Services', value: 9 },
    { label: 'Old Mutual Digital Services (O\’mari)', value: 10 },
  ]

  const [bu, setBU] = useState();

  const oldData = retrieveObjectData();

  const [firstname, onfirstnameChange] = useState(oldData.firstname);
  const [lastname, onlastnameChange] = useState(oldData.lastname);
  const [phone, onphoneChange] = useState(oldData.phone);
  const [email, onemailChange] = useState(oldData.email);
  const [policy, onPolicyChange] = useState(oldData.email);
  const [id, setId] = useState("");
  const [errorsHas, setErrorsHas] = useState(false);
  const [firstNameExists, setFirstNameExists] = useState(true);
  const [lastNameExists, setLastNameExists] = useState(true);
  const [phoneExists, setPhoneExists] = useState(true);
  const [state, setState] = useState({ ...oldData });

  const handleChange = (e) => {
    const newData = { ...state, [e.target.name]: e.target.value };
    setState(newData);
    storeObjectData(newData);
  };


  const handleComplaintSubmission = () => {
    ref.current.continuousStart();
    setLoading(true);
    const postData = {
      "complaint": complaint,
      "product_id": bu,
      "category_id": 19,
      "channel_id": 3,
      tablet_id: 105,
      phone: state.countryCode + phone,
      firstname: firstname,
      lastname: lastname,
      email: email,
      id_number: id,
      policy: policy
    };

    SentimentApi.postComplaint(postData).then((response) => {
      ref.current.complete();
      navigate("/success");
      setLoading(false);
    }).then((error) => {
      setLoading(false);
    });

  };

  // #2e3f77
  const cabsSite = !window.location.origin.includes("cabs");

  const checkUser = () => {
    if (!firstname?.trim()) {
      setFirstNameExists(false);
    } else {
      setFirstNameExists(true);
    };

    if (!lastname?.trim()) {
      setLastNameExists(false);
    } else {
      setLastNameExists(true);
    };

    if (!phone?.trim()) {
      setPhoneExists(false)
    } else {
      setPhoneExists(true);
    };
  }


  const goBack = () => {
    navigate('/')
  };

  return (
    <div className="feedback-screen">
      <div className='feedback-bg'></div>
      <img style={{ width: '150px' }} src={window.location.origin.includes("cabs") ? cabs : logo} alt="logo" />
      <div className="top-container">
        <p style={{ color: '#000', fontSize: 12, marginTop: 10, cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: '#000' }}><span style={{ color: 'blue' }}>Home</span></a> ~ complaint
        </p>
        <h2 className="title" style={{ color: '#000', marginTop: 5 }}>Complaint</h2>
        <p className="textbelow" >
          We take customer feedback very seriously, so if you have any thoughts or concerns about our services we encourage you to lodge a complaint.
        </p>
      </div>
      <LoadingBar color='green' progress={5} ref={ref} />
      <div className="bottom-container">
        <input
          type="text"
          placeholder="First Name"
          value={firstname}
          name="firstname"
          onChange={(e) => {
            onfirstnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastname}
          name="lastname"
          onChange={(e) => {
            onlastnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input type="text" name="countryCode" value={state.countryCode} onChange={handleChange} placeholder="+263" style={{ width: '15%' }} />
          <input
            type="text"
            placeholder="Phone Number"
            value={phone}
            name="phone"
            onChange={(e) => {
              onphoneChange(e.target.value)
              handleChange(e);
            }}
          />
        </div>
        <input
          type="text"
          placeholder="Account/Policy Number"
          value={policy}
          name="policy"
          onChange={(e) => {
            onPolicyChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Email"
          value={email}
          name="email"
          onChange={(e) => {
            onemailChange(e.target.value)
            handleChange(e);
          }}
        />
        <p style={{ marginLeft: 10, marginTop: 10, marginBottom: 0 }}>Please Select Business Unit</p>
        <select className='option'
          onChange={(x) => setBU(x.target.value)}>
          <option>Select item</option>
          {
            bus.map((x) => {
              return <option value={x.value}>{x.label}</option>
            })
          }
        </select>
        <p style={{ marginLeft: 10, marginTop: 10, marginBottom: 0 }}>Enter Complaint Description</p>

        <textarea
          name='description'
          placeholder="eg, poor service"
          value={complaint}
          onChange={(e) => setComplaint(e.target.value)}
        />
        {/* #00763D */}
        <button className="button"
          style={{ backgroundColor: cabsSite ? "#00763D" : "#2e3f77" }}
          disabled={loading || !complaint}
          onClick={handleComplaintSubmission}>
          {
            loading ? <span class="loader"></span> : <span className="submitText">SUBMIT COMPLAINT</span>
          }
        </button>
        <div className="buttonContainer">
          <div className="goBack" onClick={goBack}>
            <FaHome className="goBackIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complaint;
