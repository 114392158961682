import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.png"
import footer from "../assets/images/icon.png"
import successGif from "../assets/images/success.gif"
import "../assets/css/success.css"
import cabs from "../assets/images/cabs.png";

const SuccessPage = (props) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const clearMain = setTimeout(() => {
      navigate('/');
    }, 3000);

    return () => {
      clearTimeout(clearMain);
    };
  }, []);

  return (
    <div className="containerComplaintsFeedback" style={{height:'100vh'}}>
           <img style={{width:'150px'}} src={window.location.origin.includes("cabs") ? cabs : logo} alt="logo"/>

        <h2 className="title">Customer Feedback</h2>
        <p style={{ fontSize: 15, fontFamily: 'Montserrat',margin:0,textAlign:'center' }}>
          your feedback has been submitted successfully
        </p>
        <img
          style={{height:150,marginTop:50}}
          className="tickImage"
          src={successGif}
          alt='success-gif'
        />
      {!window.location.origin.includes("cabs") && <div className="footer">
        <img className="footer-image" src={footer} alt="icon" />
        <p className="footer-text">DO GREAT THINGS EVERYDAY</p>
      </div>}
    </div>
  );
};

export default SuccessPage;
