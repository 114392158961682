import React, { useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.png"
import { FaHome } from 'react-icons/fa';
import SentimentApi from '../api/SentimentApi';
import LoadingBar from 'react-top-loading-bar';
import cabs from "../assets/images/cabs.png";
import { retrieveObjectData, storeObjectData } from './Util';

const Compliment = () => {
  const navigate = useNavigate();


   const ref = useRef(null);

   const bus = [
    { label: 'CABS', value: 1 },
    { label: 'Old Mutual Life Assurance', value: 2 },
    // { label: 'Retail', value: 3 },
    { label: 'Old Mutual Unit Trusts', value: 4 },
    { label: 'Old Mutual Short-term Insurance', value: 5 },
    { label: 'Old Mutual Finance', value: 6 },
    { label: 'Old Mutual Investments', value: 7 },
    // { label: 'Group Shared Services', value: 9 },
    { label: 'Old Mutual Digital Services (O\’mari)', value: 10 },
  ]


 const [isUSerRegistered, setIsUSerRegistered] = useState(false);
const [stateLoading, setStateLoading] = useState(true);
const [open, setOpen] = useState(false);
const [value, setValue] = useState(null);
const [token, setToken] = useState("");
const [loading, setLoading] = useState(false);
const [complaint, setComplaint] = useState("");
const [buvalue, setbuValue] = useState(null);
const [buopen, setBuOpen] = useState(false);
const [bu, setBU] = useState([
  { label: 'CABS', value: 1 },
  { label: 'Old Mutual Life Assurance', value: 2 },
  // { label: 'Retail', value: 3 },
  { label: 'Old Mutual Unit Trusts', value: 4 },
  { label: 'Old Mutual Short-term Insurance', value: 5 },
  { label: 'Old Mutual Finance', value: 6 },
  { label: 'Old Mutual Investments', value: 7 },
  // { label: 'Group Shared Services', value: 9 },
  { label: 'Old Mutual Digital Services (O\’mari)', value: 10 },
]);
const [isComplaintEntered, setIsComplaintEntered] = useState(true);
const [isCategorySelected, setIsCategorySelected] = useState(true);
const [isProductSelected, setIsProductSelected] = useState(true);

const backClick =()=>{
    navigate("Home");
};

const oldData = retrieveObjectData();
  
const [firstname,onfirstnameChange] = useState(oldData.firstname);
const [lastname,onlastnameChange] = useState(oldData.lastname);
const [phone,onphoneChange] = useState(oldData.phone);
const [email,onemailChange] = useState(oldData.email);
const [policy,onPolicyChange] = useState(oldData.policy);
const [id, setId] = useState("");
const [errorsHas, setErrorsHas] = useState(false);
const [firstNameExists, setFirstNameExists] = useState(true);
const [lastNameExists, setLastNameExists] = useState(true);
const [phoneExists, setPhoneExists] = useState(true);
const [state, setState] = useState({...oldData});

const handleChange = (e) => {
  const newData = { ...state, [e.target.name]: e.target.value };
  setState(newData);
  storeObjectData(newData);
};

const checkUser = () => {
    if(!firstname?.trim()){
        setFirstNameExists(false);
    }else{
        setFirstNameExists(true);
    };

    if(!lastname?.trim()){
        setLastNameExists(false);
    }else{
        setLastNameExists(true);
    };

    if(!phone?.trim()){
        setPhoneExists(false)
    }else{
        setPhoneExists(true);
    };
};

  const cabsSite  = !window.location.origin.includes("cabs");

  const handleComplaintSubmission = () => {
    
    ref.current.continuousStart();
    
    setLoading(true);

    const postData = {
        "complaint":complaint,
        "product_id":buvalue,
        "category_id":19,
        "channel_id":3,
        tablet_id: 105,
        phone: state.countryCode + phone,
        firstname:firstname,
        lastname:lastname,
        email:email,
        id_number:id,
        policy:policy
    };

    SentimentApi.postCompliment(postData).then((response) => {
     ref.current.complete();
     navigate("/success");
     setLoading(false);
    }).then((error) => {
        setLoading(false);
    });
  }

    const handleComplaintPost = () => {
        if(firstname && lastname && phone && buvalue && complaint){
            handleComplaintSubmission();
        }

        if(!value){
            setIsCategorySelected(false);
        }else{
            setIsCategorySelected(true);
        }

        if(!buvalue){
            setIsProductSelected(false);
        }else{
            setIsProductSelected(true);
        }

        if(!complaint){
            setIsComplaintEntered(false);
        }else{
            setIsComplaintEntered(true);
        }
    
}

  const goBack = () => {
    navigate('/')
  }

  return (
      <div className="feedback-screen">
      <LoadingBar color='green' progress={5} ref={ref} />
      <div className='feedback-bg'></div>
      <img style={{width:'150px'}} src={window.location.origin.includes("cabs") ? cabs : logo} alt="logo"/>

      <div className="top-container">
      <p style={{ color: '#000', fontSize: 12, marginTop: 10, cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: '#000' }}><span style={{ color: 'blue' }}>Home</span></a> ~ compliment
        </p>
       <h2 className="title" style={{color:'#000'}}>Compliment</h2>
          <p className="textbelow">
            We want to hear what you have to say! Tell us what you think of our work and give us your valuable compliment. 
          </p>
      </div>
      <div className="bottom-container">
      <input
          type="text"
          placeholder="First Name"
          value={firstname}
          name="firstname"
          onChange={(e) => {
            onfirstnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastname}
          name="lastname"
          onChange={(e) => {
            onlastnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input type="text" name="countryCode" value={state.countryCode} onChange={handleChange} placeholder="+263" style={{ width: '15%' }} />
          <input
            type="text"
            placeholder="Phone Number"
            value={phone}
            name="phone"
            onChange={(e) => {
              onphoneChange(e.target.value)
              handleChange(e);
            }}
          />
        </div>
        <input
          type="text"
          placeholder="Account/Policy Number"
          value={policy}
          name="policy"
          onChange={(e) => {
            onPolicyChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Email"
          value={email}
          name="email"
          onChange={(e) => {
            onemailChange(e.target.value)
            handleChange(e);
          }}
        />
        <p style={{marginLeft:10,marginTop:10,marginBottom:0}}>Please Select Business Unit</p>
        <select className='option'
          onChange={(x)=>setBU(x.target.value)}>
            <option>Select item</option>
            {
              bus.map((x)=>{
                return <option value={x.value}>{x.label}</option>
              })
            }
        </select>
         <p style={{marginLeft:10,marginTop:10,marginBottom:0}}>Enter Compliment Description</p>
        <textarea
          name='description'
          placeholder="eg, great service"
          value={complaint}
          onChange={(e) => setComplaint(e.target.value)}
        />
      <button className="button" 
       style={{backgroundColor: cabsSite ? "#00763D" : "#2e3f77"}}
       disabled={loading||!complaint}
       onClick={handleComplaintSubmission}>
        {
          loading ? <span class="loader"></span> : <span className="submitText">SUBMIT</span>
        }
      </button>
      <div className="buttonContainer">       
        <div className="goBack" onClick={goBack}>
          <FaHome className="goBackIcon"/>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Compliment;
