import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter,HashRouter} from 'react-router-dom';
import Homepage from './pages/HomePage';
import Complaint from './pages/Complaint';
import NetPromoterScore from './pages/NetPromoterScore';
import Compliment from './pages/Compliment';
import CustomerSatifactionScore from './pages/CustomerSatifactionScore';
import SuccessPage from './pages/SuccessPage';
import CustomerEffortScore from './pages/CustomerEffortScore';
import "./assets/css/homepage.css";
import { useEffect } from 'react';

const changeFavicon = link => {
  let $favicon = document.querySelector('link[rel="icon"]')
  // If a <link rel="icon"> element already exists,
  // change its href to the given link.
  if ($favicon !== null) {
    $favicon.href = link
  // Otherwise, create a new element and append it to <head>.
  } else {
    $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = link
    document.head.appendChild($favicon)
  }
}

function Home() {
  return (
    <>
      <Homepage />
    </>
  );
}


function App() {
  useEffect(()=>{
    window.location.origin.includes("cabs") && changeFavicon("http://www.cabs.co.zw/sites/default/files/fav.ico");
    document.title = window.location.origin.includes("cabs") ? "CABS - Customer Feedback" : "Old Mutual - Customer Feedback";
  },[])
  return (
    <>
    <HashRouter />
      <Router>    
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/customer-effort-score" element={<CustomerEffortScore />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/net-promoter-score" element={<NetPromoterScore />} />
          <Route path="/customer-satisfaction-score" element={<CustomerSatifactionScore />} />
          <Route path="/compliment" element={<Compliment />} />
        </Routes>
    </Router>
  <HashRouter/>
  </>
  );
}

export default App;
