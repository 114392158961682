export const storeObjectData = (value)=>{
    const obj = JSON.stringify(value);
    localStorage.setItem("state",obj);

    return;
};

export const retrieveObjectData = ()=>{
    const data = localStorage.getItem("state")
    if(!data){
        return {}
    } else return JSON.parse(data);
};