import axios from "axios";

const ApiClient  = () =>{
    
    const axiosInstance = axios.create(
        {
            // baseURL:'https://seal-app-zgmb6.ondigitalocean.app',
            baseURL:window.location.origin+'/api/v1',
            responseType:'json'
        }
    );

  axiosInstance.defaults.headers.post['Content-Type']   = 'application/json';
  axiosInstance.defaults.headers.post['x-access-token'] = '';

  
  axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const message = error?.response?.data ? JSON.stringify(error?.response?.data?.message) : error.message || error;
        return Promise.reject(message);
    }
);

    return axiosInstance

}

export default ApiClient;