import React, { useEffect } from 'react'
import logo from "../assets/images/logo.png"
import cabs from "../assets/images/cabs.png"
import complaint from "../assets/images/complaint.png"
import complaint_cabs from "../assets/images/complaint_cabs.png"
import compliment from "../assets/images/compliment.png"
import compliment_cabs from "../assets/images/compliment_cabs.png"
import rate from "../assets/images/rate.png"
import rate_cabs from "../assets/images/rate_cabs.png"
import icon from "../assets/images/icon.png"
import { useNavigate } from 'react-router-dom'

function Homepage() {

  const navigate = useNavigate();

  const complaintClick = () => {
    // Functionality for when complaint element is clicked
    navigate('/complaint')
  };

  const complimentClick = () => {
    // Functionality for when compliment element is clicked
    navigate('/compliment')
  };

  const satisfactionClick = () => {
    // Functionality for when satisfaction score element is clicked
    navigate('/customer-satisfaction-score')
  };

  const ratingClick = () => {
    // Functionality for when rating element is clicked
    navigate('/net-promoter-score')
  };

  const effortClick = () => {
    navigate('/customer-effort-score')
  };

  const cabsSite = !window.location.origin.includes("cabs");

  return (
    <div className="feedback-screen" style={{ paddingTop: 40 }}>
      <div className='feedback-bg'></div>
      <div className="top-container">
        <img style={{ width: '150px', marginRight: 20 }} src={window.location.origin.includes("cabs") ? cabs : logo} alt="logo" />
        <h2 className="title" style={{ color: '#000', fontSize: 18 }}>Customer Feedback</h2>
        {/* <p style={{fontSize:15,color:'#000',}}>select an option below</p> */}
      </div>
      <div className="bottom-container">
        <div className="inner-element" onClick={complaintClick}>
          <img className="element-image" src={cabsSite ? complaint : complaint_cabs} alt="complaint" />
          <div className="element-text">
            <h2 className="title-header">Complaint</h2>
            <p className="element-description">Lodge a complaint and we will better our service</p>
          </div>
        </div>
        <div className="inner-element" onClick={complimentClick}>
          <img className="element-image" src={cabsSite ? compliment : compliment_cabs} alt="compliment" />
          <div className="element-text">
            <h2 className="title-header">Compliment</h2>
            <p className="element-description">Leave a compliment on our great service</p>
          </div>
        </div>
        <div className="inner-element" onClick={ratingClick}>
          <img className="element-image" src={cabsSite ? rate : rate_cabs} alt="rating" />
          <div className="element-text">
            <h2 className="title-header">Rate our services</h2>
            <p className="element-description">How likely is it that you would recommend us to a friend or colleague  ? (Rating of 1 to 10, 1 being the lowest)</p>
          </div>
        </div>
        <div className="inner-element" onClick={satisfactionClick}>
          <img className="element-image" src={cabsSite ? rate : rate_cabs} alt="satisfaction" />
          <div className="element-text">
            <h2 className="title-header">Satisfaction Score</h2>
            <p className="element-description">On a scale of 1 to 10, how satisfied are you with our services ?</p>
          </div>
        </div>
        <div className="inner-element" onClick={effortClick}>
          <img className="element-image" src={cabsSite ? rate : rate_cabs} alt="satisfaction" />
          <div className="element-text">
            <h2 className="title-header">Effort Score</h2>
            <p className="element-description">On a scale of 1 to 7, (1 being very difficult and 7 being easy) how easy is it getting the service you want at {cabsSite ? 'Old Mutual' : 'CABS'}</p>
          </div>
        </div>

      </div>
      {!window.location.origin.includes("cabs") && <div className="footer">
        <img className="footer-image" src={icon} alt="icon" />
        <p className="footer-text">DO GREAT THINGS EVERYDAY</p>
      </div>}
    </div>
  )
}

export default Homepage
